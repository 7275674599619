@use '../../../node_modules/@angular/material/index' as mat;
@include mat.core();

// Para cada paleta de color se pueden elegir hasta 4 matices (defecto, más claro, más oscuro y texto)
$my-primary-dark: mat.define-palette(mat.$green-palette, 400, 100, 900, 900);
$my-accent-dark: mat.define-palette(mat.$deep-orange-palette, A200, A100, A400);

$my-primary-light: mat.define-palette(mat.$green-palette, 700, 700, 700, 900);
$my-accent-light: mat.define-palette(mat.$deep-orange-palette, A200, A100, A400);

$my-light-theme: mat.define-light-theme((
  color: (
    primary: $my-primary-light,
    accent: $my-accent-light,
  ),
  typography: mat.define-typography-config(),
  density: 0,
));

$my-dark-theme: mat.define-dark-theme((
  color: (
    primary: $my-primary-dark,
    accent: $my-accent-dark,
  ),
  typography: mat.define-typography-config(),
  density: 0,
));

.light-theme {
  // Emit theme-dependent styles for common features used across multiple components.
  @include mat.all-component-themes($my-light-theme);
  @include mat.button-theme($my-light-theme);
  @include mat.all-component-typographies($my-light-theme);
  @include mat.all-component-colors($my-light-theme);
}

.dark-theme {
  // Emit theme-dependent styles for common features used across multiple components.
  @include mat.all-component-themes($my-dark-theme);
  @include mat.button-theme($my-dark-theme);
  @include mat.all-component-typographies($my-dark-theme);
  @include mat.all-component-colors($my-dark-theme);
}

// Emit theme-dependent styles for common features used across multiple components.
@include mat.all-component-themes($my-light-theme);
@include mat.button-theme($my-light-theme);
@include mat.all-component-typographies($my-light-theme);
@include mat.all-component-colors($my-light-theme);
