/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 5px;  }

/* 2022-dic Migración Angular Material 15. Quito monoespaciado para que los textos ocupen menos */
.mdc-button__label, /* El texto de botones*/
.mdc-tab__content,  /* texto de las pestañas */
.mat-mdc-select,    /* Texto del select */
.mat-mdc-option     /* Texto de opciones del select */
{
  letter-spacing: 0px;
}

/* Ajustes por cambio del tema por defecto */
.mat-badge-content {
  color: #ffffff !important; /* cambia el color del texto a blanco */
}

/* Centra un div en la pantalla */
.div-center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

/* Ajusta un div a la izda */
.div-left {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
.mat-card-mini {
  text-align: center; /* Centra el título de la tarjeta */
  width: fit-content; /* Reduce el ancho de la tarjeta a lo imprescindible */
}

/* Quitar subrayado a links */
a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:visited {
  color: inherit;
}

/* cualquier cosa con link o llamada a función mostramos cursor */
[ng-reflect-router-link],
[ng-click],
[data-ng-click],
[x-ng-click],
a[routerLink]
{
  cursor: pointer;
}

/* Centramos los iconos verticalmente */
.mat-icon {
  vertical-align: middle;
}

.botonera-flex-container {
  /* ajusta los elementos en filas según el tamaño con cierta separación entre ellos
     para que no aperezcan apelotonados */
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
}
/* Añado margen para que no se monten las filas de elementos en caso de multilinea */
.botonera-flex-container > * {
  margin: 5px;
}

.botonera-flex-extremos {
  /* ajusta los elementos a los extremos */
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: baseline;

  margin: 0;
  padding: 0;
}

.botonera-flex-evenly {
  /* ajusta los a los extremos dejando espacio*/
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: baseline;

  margin: 0;
  padding: 0;
}

.botonera-flex-start {
  /* ajusta los elementos a la izquierda */
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;

  margin: 0;
  padding: 0;
}

.botonera-flex-end {
  /* ajusta los elementos a la derecha */
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;

  margin: 0;
  padding: 0;
}


.botonera-flex-item {
  /* espacio entre cada elemento de una botonera */
  margin:  5px !important;
}

tr:hover td {
  /* colorear la fila de la tabla sobre la que se encuentra el cursor */
  background-color: rgba(204, 239, 185, 0.78);
}

/* Posicionar botón de cierre en los diálogos*/
.close-button{
  float: right;
}
/* animación del botón de cierre */
.close-icon {
  transition: 1s ease-in-out;
}
.close-icon:hover {
  transform: rotate(180deg);
}
/* input en diálogos de ancho completo */
.full-width {
  width: 100%;
}
.half-width {
  width: 50%;
}

/* tabla admin cebreada no mat-table  */
tr:nth-child(odd){
  background: rgba(0,0,0,0.050);
}

/* Estilos para hacer más pequeño el stepper del diálogo crear cambio */
.mat-horizontal-stepper-header {
  height: 40px !important;
  padding: 5px !important;
}
.mat-horizontal-content-container {
  padding: 5px !important;
}

/* Colores de evaluaciones ------ */
.negrita {
  font-weight: bold;
}
.perfect, a.perfect {
  color: rgba(25, 168, 25);
}
.normal, a.normal {
  color: olive;
}
.warning, a.warning {
  color: coral;
}
.danger, a.danger {
  color: red;
}
.poco, a.poco {
  color: rgba(136, 136, 136, 0.88);
}

.icon-half {
  transform: scale(0.5);
}

/* Fondo de icono simulación de menu */
.icono-demo {
  color: darkgoldenrod;
}

/* Indentar parrafo */
.tab { margin-left: 20px; }

/* Parrafo de texto para que se alargue mucho en pantallas grandes */
.bloque-texto {
  max-width: 800px;
}

/**************** SUSTITUCIONES ANGULAR-FLEX-LAYOUT fxHide/fxShow **************/
@media screen and (max-width: 600px) {
  /* Oculta en móvil */
  .hide-xs {
    display: none;
  }
}

@media screen and (min-width: 600px) {
  /* Oculta en mayor que móvil */
  .hide-gt-xs {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  /* Oculta en menor que tablet */
  .hide-lt-md {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  .hide-lt-xl {
    /* Oculta en menor que ordenador */
    display: none;
  }
}

.lineas-juntas {
  line-height: 0.8;
}

.tachado {
  text-decoration: line-through;
  opacity: 0.8;
  /*text-decoration-thickness: 2px;*/
}

